import { Link } from "react-router-dom";
import { Linkify } from "./linkify";
import { withDefault } from "../../layouts/Default";
import { Metadata } from "../../types/types";

const metadata: Metadata = {
  title: "Work",
  description: "A summary of my work and contributions.",
};

function WorkPage() {
  return (
    <section className="tracking-wide lg:pr-[25%]">
      <h1 className="mb-6 text-2xl font-medium tracking-tighter text-gray-800 dark:text-gray-200">
        My Work
      </h1>
      <div className="prose prose-neutral max-w-max dark:prose-invert">
        <p className="text-sm italic text-gray-500 transition duration-300 dark:text-gray-400">
          Additionally, I have curated{" "}
          <Link to="/blog?tag=project" className="link">
            blogs
          </Link>{" "}
          detailing the projects and work I've been involved with, providing insights into my
          professional journey.
        </p>
        <hr className="my-6 border-neutral-200 dark:border-neutral-800" />
        {/* ------------------------------------------------------------------- */}
        <section className="mb-8 transition duration-300 hover:text-gray-900 dark:hover:text-gray-100">
          <h2 className="mb-1 text-xl font-medium tracking-tighter text-gray-700 dark:text-gray-200">
            <a
              href="https://www.algonquincollege.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="link-no-underline"
            >
              Algonquin College of Applied Arts and Technology
            </a>
          </h2>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Academic - Non-Teaching, October 2024 - Present · Hybrid
          </p>
          <p className="text-gray-600 transition duration-300 dark:text-gray-400">
            Leading development of campus infrastructure and educational initiatives focused on
            building automation and energy monitoring.
          </p>
          <ul className="list-inside list-disc text-gray-600 transition duration-300 dark:text-gray-400">
            <li>
              Developing campus-wide energy and sensor monitoring system by integrating building
              automation data onto modern technology stack like Node.js,PostgreSQL and Grafana
            </li>
            <li>
              Creating BACnet device discovery tool using Raspberry Pi for automated network
              detection and maping of devices on campus subnets
            </li>
            <li>
              Designing laboratory curriculum covering industrial protocols and time series analysis
            </li>
            <li>
              Implementing backend architecture and Grafana dashboards for system visualization
            </li>
            <li>Establishing version control practices and documentation standards</li>
          </ul>
          <p className="text-sm text-gray-600 transition duration-300 dark:text-gray-400">
            <strong>Tech Stack:</strong> Node.js, Express, PostgreSQL, Grafana, BACnet, Python, Git
          </p>
        </section>
        {/* ------------------------------------------------------------------- */}
        <hr className="my-6 border-neutral-200 dark:border-neutral-800" />
        <section className="mb-8 transition duration-300 hover:text-gray-900 dark:hover:text-gray-100">
          <h2 className="mb-1 text-xl font-medium tracking-tighter text-gray-700 dark:text-gray-200">
            <a
              href="https://www.algonquincollege.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="link-no-underline"
            >
              Algonquin College of Applied Arts and Technology
            </a>
          </h2>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Research Assistant, June 2024 - October 2024 · Hybrid
          </p>
          <p className="text-gray-600 transition duration-300 dark:text-gray-400">
            Developed comprehensive flight data collection and analysis systems for aircraft
            monitoring and safety.
          </p>
          <ul className="list-inside list-disc text-gray-600 transition duration-300 dark:text-gray-400">
            <li>Developed microcontroller program for GPS and IMU sensor integration via UART</li>
            <li>Implemented computer vision solution for analog airspeed gauge digitization</li>
            <li>Designed distributed black box system for multi-aircraft data collection</li>
            <li>Built server infrastructure for automated flight data synchronization</li>
            <li>Created flight analysis tools using JavaScript Canvas and Tableau</li>
          </ul>
          <p className="text-sm text-gray-600 transition duration-300 dark:text-gray-400">
            <strong>Tech Stack:</strong> ESP32, OpenCV, GPS, IMU, JavaScript Canvas, Tableau, WiFi
            protocols, UART
          </p>
        </section>
        {/* ------------------------------------------------------------------- */}
        <hr className="my-6 border-neutral-200 dark:border-neutral-800" />
        <section className="mb-0 transition duration-300 hover:text-gray-900 dark:hover:text-gray-100">
          <h2 className="mb-1 text-xl font-medium tracking-tighter text-gray-700 dark:text-gray-200">
            <Link to="/blog?tag=freelance" className="link-no-underline">
              Freelancing
            </Link>
          </h2>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Web Generalist, July 2022 - Present
          </p>
          <p className="text-gray-600 transition duration-300 dark:text-gray-400">
            As a freelance web developer, I have worked on a variety of projects ranging from static
            websites to full-stack applications. My work includes developing custom themes,
            optimizing performance, and enhancing user experiences.
          </p>
          <Link to="/blog?tag=freelance" className="link">
            Click here to read blog's dedicated to the work I've done as a Freelancer.
          </Link>
        </section>
        {/* ------------------------------------------------------------------- */}
        <hr className="my-6 border-neutral-200 dark:border-neutral-800" />
        <section className="transition duration-300 hover:text-gray-900 dark:hover:text-gray-100">
          <h2 className="mb-1 text-xl font-medium tracking-tighter text-gray-700 dark:text-gray-200">
            <a href="https://www.artfervour.com" target="_blank" className="link-no-underline">
              Art Fervour
            </a>
          </h2>
          <p className="text-sm text-gray-600 transition duration-300 dark:text-gray-400">
            Product Developer, June 2020 to May 2022 (2y)
          </p>
          <i className="text-gray-600 transition duration-300 dark:text-gray-400">
            Art Fervour stands as India's pioneering visual arts discovery platform, led by a
            dynamic team of young women. They blend creativity with technology to democratize the
            arts, making it an accessible, everyday experience with just a click.
          </i>
          <p className="text-gray-600 transition duration-300 dark:text-gray-400">
            In my time at Art Fervour, I was responsible for optimizing data from viewers to make
            better choices, developing SEO strategies and creating interactive marketing website
            games for holding users. This position allowed me to combine technical ability with
            creative marketing strategy effectually.
          </p>
          <ul className="list-inside list-disc text-gray-600 transition duration-300 dark:text-gray-400">
            <li>Developed custom forms and mini-games, enhancing marketing engagement.</li>
            <li>Managed website upkeep for optimal functionality and reliability.</li>
            <li>Conducted SEO checks, optimizing website performance.</li>
            <li>Utilized Google Analytics for insightful marketing strategy adjustments.</li>
            <li>Created interactive marketing games, increasing user interaction.</li>
            <li>Improved SEO and game features, boosting user satisfaction.</li>
          </ul>
          <Link to="/blog?tag=artfervour" className="link">
            Click here to read a blog dedicated to the work I've done at ArtFervour.
          </Link>
        </section>
        {/* ------------------------------------------------------------------- */}
        <hr className="mb-0 mt-8 border-neutral-200 dark:border-neutral-800" />
        <section className="transition duration-300 hover:text-gray-900 dark:hover:text-gray-100">
          <h2 className="mb-1 text-xl font-medium tracking-tighter text-gray-700 dark:text-gray-200">
            <a href="https://www.pointo.in" target="_blank" className="link-no-underline">
              Pointo
            </a>
          </h2>
          <p className="text-sm text-gray-600 transition duration-300 dark:text-gray-400">
            Full Stack Lead Developer, April 2020 to September 2021 (1y 6m)
          </p>
          <i className="text-gray-600 transition duration-300 dark:text-gray-400">
            Pointo, founded by IIT alumni, revolutionizes battery financing and the clean-tech
            ecosystem, enhancing living standards through accessible, sustainable solutions.
          </i>
          <ul className="list-inside list-disc text-gray-600 transition duration-300 dark:text-gray-400">
            <li>
              Orchestrated the development of an <Linkify str="Angular" nt />
              -based admin management dashboard, optimizing administrative workflows and boosting
              productivity.
            </li>
            <li>
              Engineered scalable and high-performance solutions by extending the{" "}
              <Linkify str="Node.js" nt /> backend, ensuring seamless operation under high loads.
            </li>
            <li>
              Architected and integrated microservices into the existing system, enhancing
              scalability, fault tolerance, and overall system reliability.
            </li>
            <li>
              Crafted intuitive and responsive user interfaces for customer-facing applications,
              leveraging <Linkify str="React.js" nt /> and ensuring exceptional user engagement and
              satisfaction.
            </li>
            <li>
              Implemented an operations management dashboard utilizing technologies like MongoDB and{" "}
              <Linkify str="Express.js" nt />, streamlining internal processes and optimizing
              operational efficiency.
            </li>
          </ul>
          <p className="text-sm text-gray-600 transition duration-300 dark:text-gray-400">
            <strong>Tech Stack:</strong> Angular, React, NodeJS, MySQL, Postgres, Sentry, Firebase:
            Firestore, Crashlytics, Authentication, Storage AWS: lambda, DynamoDB, GCP: Cloud
            functions, Big Query, Maps API.
          </p>
          <Link to="/blog?tag=pointo" className="link">
            Click here to read a blog dedicated to the work I've done at Pointo.
          </Link>
        </section>
        {/* ------------------------------------------------------------------- */}
        <hr className="my-6 border-neutral-200 dark:border-neutral-800" />
        <section className="transition duration-300 hover:text-gray-900 dark:hover:text-gray-100">
          <h2 className="mb-4 text-2xl font-medium tracking-tighter text-gray-700 dark:text-gray-50">
            My Education
          </h2>
          <div className="mb-8">
            <h3 className="mb-2 text-lg font-semibold text-gray-600 dark:text-gray-200">
              <a
                href="https://www.algonquincollege.com/sat/program/business-intelligence-system-infrastructure/"
                target="_blank"
                rel="noopener noreferrer"
                className="link-no-underline"
              >
                Business Intelligence & System Infrastructure
              </a>
            </h3>
            <p className="text-sm italic text-gray-600 dark:text-gray-300">
              Algonquin College, Ottawa, Ontario, 2023-2024
            </p>
            <p className="text-sm text-gray-600 transition duration-300 dark:text-gray-400">
              Course units include IBM Cognos, Tableau, Power BI, Oracle Database, Cloud
              Foundations, and Python programming.
            </p>
          </div>
          <div className="mb-8">
            <h3 className="mb-2 text-lg font-semibold text-gray-600 dark:text-gray-200">
              <a
                href="https://appliedtechnology.humber.ca/programs/cloud-computing.html"
                target="_blank"
                rel="noopener noreferrer"
                className="link-no-underline"
              >
                Cloud Computing
              </a>
            </h3>
            <p className="text-sm italic text-gray-600 dark:text-gray-300">
              Humber College, Toronto, Ontario, January 2023 - August 2023
            </p>
            <p className="text-sm text-gray-600 transition duration-300 dark:text-gray-400">
              Course units include Operating Systems, Virtualization, Software Defined Network,
              Application Programming, Databases, Project Management, Cloud Solution Architecture,
              Cloud Security, Automation, Big Data Analytics, and DevOps for Cloud Computing.
            </p>
          </div>
          <div className="mb-8">
            <h3 className="mb-2 text-lg font-semibold text-gray-600 dark:text-gray-200">
              <a
                href="https://makautwb.ac.in/"
                target="_blank"
                rel="noopener noreferrer"
                className="link-no-underline"
              >
                Master of Computer Applications
              </a>
            </h3>
            <p className="text-sm italic text-gray-600 dark:text-gray-300">
              WBUT, West Bengal, India, 2020-2022
            </p>
            <p className="text-sm text-gray-600 transition duration-300 dark:text-gray-400">
              Study areas include Databases, Algorithms, Computer Organization, Programming, and
              Software Development.
            </p>
          </div>
          <div>
            <h3 className="mb-2 text-lg font-semibold text-gray-600 dark:text-gray-200">
              <a
                href="https://makautwb.ac.in/"
                target="_blank"
                rel="noopener noreferrer"
                className="link-no-underline"
              >
                Bachelor of Computer Applications
              </a>
            </h3>
            <p className="text-sm italic text-gray-600 dark:text-gray-300">
              WBUT, West Bengal, India, 2017-2020
            </p>
            <p className="text-sm text-gray-600 transition duration-300 dark:text-gray-400">
              Study areas include Programming and Design of Software products.
            </p>
          </div>
        </section>
      </div>
    </section>
  );
}

export default withDefault(<WorkPage />, metadata);
